export const FILTER = {
	include: [
		'users',
		'county',
		'logs',
		'phonebooks',
		{ relation: 'managementGroup', scope: { include: ['managements'] } },
		{ relation: 'structure', scope: { order: 'order asc' } },
	],
};

export const DICTIONARY = [
	{
		title: 'Тип',
		pathname: 'type',
		modelName: 'OrganizationType',
	},
	{
		title: 'Тип контрагента',
		pathname: 'status',
		modelName: 'OrganizationContragentType',
	},
	{
		title: 'Группа',
		pathname: 'executionType',
		modelName: 'OrganizationGroup',
	},
	{
		title: 'Юридический статус',
		pathname: 'organizer',
		modelName: 'OrganizationStatus',
	},
];

export const DEFAULT_STRUCTURE_STYLE = {
	background: '#FFFFFF',
	colorText: '#111111',
	colorBranch: '#9DD1F0',
};
