import React from 'react';
import { Button, Phone } from 'components';
import { DatePicker, Loader, RecordSelect, Select, Input, NumberInput } from '@smartplatform/ui';
import { inject, observer } from 'mobx-react';
import { ARCHIVE_ITEMS, DATEPICKERS_CONFIG } from './constants';
import t from 'i18n';
import store from 'client/store';

const COUNT_FIRST_TYPE_MOBILE = 2;

export const Filters = inject('documentsStore')(
	observer(({ documentsStore }) => {
		const { onTypeClick, selectedType, onChange, status, showMobileType, showAllTypesMobile, subject, isLoading, types } = documentsStore;

		if (isLoading) return <Loader />;

		return (
			<div className='filters'>
				{types.length > 0 ? (
					<div className='document-types'>
						<div className='title'>{t('documentType.plural')}</div>
						<div className={`list ${showMobileType ? 'show' : ''}`}>
							{types.map((type, index) => (
								<Button
									key={type.id}
									size='small'
									color='grey'
									onClick={() => onTypeClick(type)}
									className={`${selectedType?.id === type?.id ? 'selected' : ''} ${index >= COUNT_FIRST_TYPE_MOBILE ? 'hide-mobile' : ''}`}
								>
									<span className='name'>{type.name}</span> <span className='count'>{type.documentCount}</span>
								</Button>
							))}
							<Phone>
								{!showMobileType ? (
									<p className='show-all-types' onClick={showAllTypesMobile}>
										{t('showMore')}
									</p>
								) : (
									''
								)}
							</Phone>
						</div>
					</div>
				) : (
					<div className='no-types-message'>
						<p>{t('document.noDocuments')}</p>
					</div>
				)}
				<div className='bottom'>
					{DATEPICKERS_CONFIG.map(({ key, placeholder }) => (
						<DatePicker key={key} onChange={documentsStore.onChange(key)} showTimeSelect={false} placeholder={t(placeholder)} value={documentsStore[key]} />
					))}
					<Select items={ARCHIVE_ITEMS} placeholder={t('status')} size='small' className='grey' onChange={onChange('status')} value={status} />
					<RecordSelect
						model={store.model.DocumentSubject}
						placeholder={t('documentSubject.title')}
						value={subject}
						onChange={onChange('subject')}
						property='name'
						size='small'
						className='grey'
					/>
				</div>
			</div>
		);
	})
);
