import React from 'react';
import { observer } from 'mobx-react';
import store from 'client/store';
import { observable } from 'mobx';
import { DocumentCard, AttachmentCard } from 'components';
import './style.scss';

@observer
export default class TextWithDocuments extends React.Component {
	@observable documents = [];
	@observable attachments = [];
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		if (this.props.content) {
			const { documentIds, attachmentIds } = this.extractDocumentAttachmentIds(this.props.content);
			this.fetchRecords(documentIds, attachmentIds);
		}
	};

	fetchRecords = async (documentIds, attachmentIds) => {
		if ((!documentIds || documentIds.length === 0) && (!attachmentIds || attachmentIds.length === 0)) {
			this.isLoading = false;
			return;
		}

		try {
			if (documentIds && documentIds.length > 0) {
				const documents = await store.model.Document.find({
					where: {
						id: { inq: documentIds },
					},
					include: ['organization', 'type', 'children'],
				});
				this.documents = documents;
			}

			if (attachmentIds && attachmentIds.length > 0) {
				const attachments = await store.model.Attachment.find({
					where: {
						id: { inq: attachmentIds },
					},
				});
				this.attachments = attachments;
			}
		} catch (error) {
			console.error(`Failed to fetch`, error);
		}

		this.isLoading = false;
	};

	createDocumentComponent = (id, type, document) => {
		return (
			<div className='documents-page' key={`document-${type}-${id}`}>
				{type === 'document' ? <DocumentCard record={document} /> : <AttachmentCard record={document} />}
			</div>
		);
	};

	replaceDocumentIdsWithComponent = (htmlContent) => {
		const regex = /\[(document|localDocument) id: (\d+)\]|\[filename: [^\]]+\]|\[name: [^\]]+\]/g;
		const parts = [];
		let lastIndex = 0;
		let match;

		while ((match = regex.exec(htmlContent)) !== null) {
			parts.push(
				<span key={`text-part-${lastIndex}`}>
					<span dangerouslySetInnerHTML={{ __html: htmlContent.substring(lastIndex, match.index) }} />
				</span>
			);
			
			if (match[1]) {
				const type = match[1];
				const id = Number(match[2]);

				const document = type === 'document' ? this.documents.find((doc) => doc.id === id) : this.attachments.find((attachment) => attachment.id === id);

				if (document) {
					parts.push(this.createDocumentComponent(id, type, document));
				}
			}

			lastIndex = regex.lastIndex;
		}

		parts.push(
			<span key={`text-part-${lastIndex}`}>
				<span dangerouslySetInnerHTML={{ __html: htmlContent.substring(lastIndex) }} />
			</span>
		);

		return parts;
	};

	extractDocumentAttachmentIds = (text) => {
		const documentRegex = /\[document id: (\d+)\]/g;
		const localDocumentRegex = /\[localDocument id: (\d+)\]/g;

		const documentIds = [];
		const attachmentIds = [];

		let match;

		// Поиск [document id: ]
		while ((match = documentRegex.exec(text)) !== null) {
			const id = Number(match[1]);
			if (!documentIds.includes(id)) {
				documentIds.push(id);
			}
		}

		// Поиск [localDocument id: ]
		while ((match = localDocumentRegex.exec(text)) !== null) {
			const id = Number(match[1]);
			if (!attachmentIds.includes(id)) {
				attachmentIds.push(id);
			}
		}

		return { documentIds, attachmentIds };
	};

	removeContenteditableFalseDivs = (htmlContent) => {
		// Регулярное выражение для удаления div с contenteditable="false" и всем его содержимым
		const divToRemoveRegex = /<div[^>]*contenteditable="false"[^>]*>.*?<\/div>/gs;
		// Дополнительно удаляем любые <div> или <span>, которые не нужны после удаления документов
		const unwantedTagsRegex = /<div[^>]*class="(file-size|document-info)"[^>]*>.*?<\/div>/gs;
		// Удаляем div с contenteditable="false"
		let cleanedContent = htmlContent.replace(divToRemoveRegex, '');
		// Удаляем любые другие ненужные div или span с классами file-size или document-info
		cleanedContent = cleanedContent.replace(unwantedTagsRegex, '');

		return cleanedContent;
	};

	render() {
		if (this.isLoading) return null;
		const { content, title } = this.props;
		if (!content) return null;
		const cleanedContent = this.removeContenteditableFalseDivs(content);
		let contentWithComponents = this.replaceDocumentIdsWithComponent(cleanedContent);

		return (
			<div className='wrapper-text-with-documents'>
				{title && <div className='text-block-title p4'>{title}</div>}
				<div className='management-person-page-content-text p1'>{contentWithComponents}</div>
			</div>
		);
	}
}
